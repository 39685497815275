import React, { Component } from 'react';

import {} from 'react-bootstrap';

class EditTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      item: [],
      values: [],
      options: [],
      value: ''
    };
  }

  render = () => {
    return (
      <div>
        <label>
          {this.state.name}
          {this.props.can_edit && (
            <select value={this.state.value} onChange={this.handleChange}>
              {this.state.options}
            </select>
          )}
          {!this.props.can_edit && (
            <select value={this.state.value} onChange={this.handleChange} disabled>
              {this.state.options}
            </select>
          )}
        </label>
      </div>
    );
  };

  //<p>{this.props.id}</p>

  componentDidUpdate(prevProps) {
    this.updateIfNeeded();
  }

  componentDidMount = () => {
    this.updateIfNeeded();
  };

  updateIfNeeded = () => {
    //update name if needed
    if (this.state.name !== this.props.name) {
      this.setState({ name: this.props.name });
    }
    //update item if needed
    if (JSON.stringify(this.props.item) !== JSON.stringify(this.state.item)) {
      this.setState({ item: this.props.item });
      //update values and options if needed
      if (this.props.item.values !== null) {
        this.setState({ values: this.props.item.values });
        // options
        var options = [];
        for (var x in this.props.item.values) {
          options.push(
            <option key={this.props.id + 'topedit' + x.toString()} value={this.props.item.values[x]}>
              {this.props.item.values[x]}
            </option>
          );
        }
        this.setState({ options: options });
      }
    }
    // update value if needed
    if (this.props.data !== null) {
      if (this.props.id in this.props.data) {
        var value = this.props.data[this.props.id][0];
        if (value !== this.state.value) {
          this.setState({ value: value });
        }
      }
    }
  };

  handleChange = (e) => {
    var v = e.target.value;
    let json = { globalvalue: v, for: this.props.selected_key, key: this.props.id };
    this.props.send_func(json);
    this.props.on_event('on_variant_edit');
    //this.props.on_event('on_edit');
  };
}

export default EditTop;
