import React, { Component } from 'react';

import {} from 'react-bootstrap';

import Search from './Search';
import Radio from './Radio';
import Check from './Check';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter_settings: this.props.filter_settings,
      data: this.props.data,
      search1: [],
      search2: [],
      search_ref: []
    };
  }

  /*
  <div className="filter-clear">
    <Button variant="primary shadow-none" id="filterclear" onClick={(e) => this.clear_all_filters()}>
      Clear all filters
    </Button>
  </div>
  */

  render = () => {
    return (
      <div className="filter-wrap">
        <div className="filter-fixed">{this.state.search1}</div>
        <div className="filter-scrolling">{this.state.search2}</div>
      </div>
    );
  };

  componentDidMount = () => {
    // refs are global
    var search_ref = [];
    // fixed search fields
    var fixed = [];
    var ref;
    var item;
    for (var c_f of this.state.filter_settings.fixed) {
      // search
      if (c_f.type === 'search') {
        ref = React.createRef();
        item = (
          <Search settings={c_f} ref={ref} key={'search_f' + c_f.title} refilter_func={this.props.refilter_func} />
        );
        fixed.push(item);
        search_ref.push(ref);
      }
      // radio
      if (c_f.type === 'radio') {
        ref = React.createRef();
        item = (
          <Radio
            settings={c_f}
            data={this.props.data}
            ref={ref}
            key={'radio_f' + c_f.title}
            refilter_func={this.props.refilter_func}
          />
        );
        fixed.push(item);
        search_ref.push(ref);
      }
      // check
      if (c_f.type === 'check') {
        ref = React.createRef();
        item = (
          <Check
            settings={c_f}
            data={this.props.data}
            ref={ref}
            key={'check_f' + c_f.title}
            refilter_func={this.props.refilter_func}
          />
        );
        fixed.push(item);
        search_ref.push(ref);
      }
    }
    // scrollable search fields
    var scrolling = [];
    for (var c_s of this.state.filter_settings.scrolling) {
      // search
      if (c_s.type === 'search') {
        ref = React.createRef();
        item = (
          <Search settings={c_s} ref={ref} key={'search_s' + c_s.title} refilter_func={this.props.refilter_func} />
        );
        scrolling.push(item);
        search_ref.push(ref);
      }
      // radio
      if (c_s.type === 'radio') {
        ref = React.createRef();
        item = (
          <Radio
            settings={c_s}
            data={this.props.data}
            ref={ref}
            key={'radio_s' + c_s.title}
            refilter_func={this.props.refilter_func}
          />
        );
        scrolling.push(item);
        search_ref.push(ref);
      }
      // check
      if (c_s.type === 'check') {
        ref = React.createRef();
        item = (
          <Check
            settings={c_s}
            data={this.props.data}
            ref={ref}
            key={'check_s' + c_s.title}
            refilter_func={this.props.refilter_func}
          />
        );
        scrolling.push(item);
        search_ref.push(ref);
      }
    }
    // save them all
    this.setState({
      search1: fixed,
      search2: scrolling,
      search_ref: search_ref
    });
  };

  // Called from PageApplication for each item
  // Calls filter_approves for each filter

  filter_approves = (item) => {
    for (var f of this.state.search_ref) {
      if (!f.current.filter_approves(item)) return false;
    }
    return true;
  };

  // Called from PageApplication when filter values need updating
  // Calls filter_update_values for each filter

  filter_update_values = () => {
    for (var f of this.state.search_ref) {
      f.current.filter_update_values();
    }
  };

  clear_all_filters = () => {
    for (var f of this.state.search_ref) {
      f.current.filter_clear();
    }
  };
}

export default Filter;
