import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Card, Button, Badge } from 'react-bootstrap';
import * as appUtils from '../utils';

class MatCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    // Discontinued
    var discontinued = false;
    if ('Discontinued' in this.props.data) discontinued = this.props.data['Discontinued'];
    var discontinued_color = appUtils.colorDiscontinued(discontinued);
    var discontinued_text = discontinued ? 'Discontinued' : 'Included';
    // EditState
    var es_default = this.props.edit_state.default;
    var es_values = this.props.edit_state.values;
    var es_value = es_default;
    var es_color = appUtils.colorEditState(0);
    var es_text = 'error';
    if ('EditState' in this.props.data) es_value = this.props.data['EditState'];
    var es_found = -1;
    for (var i in es_values) {
      if (es_value === es_values[i].key) {
        es_found = i;
        break;
      }
    }
    if (es_found >= 0) {
      es_color = appUtils.colorEditState(es_values[es_found].colorcode);
      es_text = es_values[es_found].human;
    }
    // badges need text color fixing
    var es_txt_color = 'dark';
    if (es_color === 'secondary' || es_color === 'dark' || es_color === 'primary' || es_color === 'success') {
      es_txt_color = 'light';
    }
    // Visible?
    var visible = this.props.data['frontend-visible'];
    //
    return (
      <div className={visible ? 'div-visible' : 'div-hidden'}>
        <Card className="card-narrow">
          <LazyLoadImage alt={this.props.id} height={200} width={200} src={this.props.image_url} />
          <Card.Body>
            <Badge bg={discontinued_color}>{discontinued_text}</Badge>{' '}
            {!(discontinued_text === 'Discontinued' && es_text === 'Released') && (
              <Badge bg={es_color} text={es_txt_color}>
                {es_text}
              </Badge>
            )}
            <Card.Title>{this.props.data['Name']}</Card.Title>
            <Button variant="outline-primary w100 shadow-none" onClick={(e) => this.doReview()}>
              Review
            </Button>
          </Card.Body>
        </Card>
      </div>
    );
  };

  componentDidUpdate(prevProps) {}

  doReview = () => {
    this.props.review_func(this.props.id);
  };

  componentDidMount = () => {};
}

export default MatCard;
