import React, { Component } from 'react';

import { Button, ButtonGroup, ButtonToolbar, Dropdown, Modal, Form, Accordion, Row, Col, Alert } from 'react-bootstrap';
import { Switch, Route } from 'react-router-dom';

import PageReview from '../pages/PageReview';
import Filter from '../components/Filter';
import MatCard from '../components/MatCard';

import logoimage from '../resources/logo.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DateTimePicker from 'react-datetime-picker';

import * as appUtils from '../utils';

var md5 = require('md5');

class PageApplication extends Component {
  constructor(props) {
    super(props);
    this.filter_ref = React.createRef();
    var sanity_report = appUtils.checkJSON();
    this.state = {
      // data
      data: [],
      selected_key: '',
      selected_hex_id: '',
      data_list: [],
      data_list_filter_info: '',
      edits: [],
      // UI
      sanity_report: sanity_report,
      show_tab: '1',
      show_release: false,
      porcelain: [],
      indexjson: [],
      show_publish_modal: false,
      release_name: '',
      release_description: '',
      release_validfrom: new Date(),
      release_validto: new Date(),
      release_error: ''
    };
  }

  //////////////////////////////
  // Data or filters are changed
  //////////////////////////////

  received_edits = (editsjson) => {
    this.setState({ edits: editsjson });
  };

  received_new_data = (datajson) => {
    var tempdata = this.state.data;
    for (const key in datajson) {
      var item = datajson[key];
      item['frontend-hex-id'] = md5(key); // add md5 to item
      item['frontend-visible'] = this.filter_ref.current.filter_approves(item); // filter on the spot
      tempdata[key] = item;
    }
    this.setState({ data: tempdata }, () => {
      this.doRegenerate();
      this.filter_ref.current.filter_update_values();
    });
  };

  doRegenerate = () => {
    var data = this.state.data;
    // data is object containing list of key: card data
    // list of keys needs to be sorted by data['Name']
    var sorted_list = [];
    for (const key in data) {
      sorted_list.push([key, data[key]['Name']]);
    }
    sorted_list.sort((a, b) => {
      var a1 = a[1].toUpperCase();
      var b1 = b[1].toUpperCase();
      return a1 === b1 ? 0 : a1 > b1 ? 1 : -1;
    });
    // then process sorted_list to pick order of keys
    var list = [];
    var visible = 0;
    var outof = 0;
    //for (const key in data) {
    for (const ind in sorted_list) {
      var key = sorted_list[ind][0];
      //console.log(key, data[key]['Name']);
      if (data[key]['frontend-visible']) visible++;
      outof++;
      var item = (
        <MatCard
          image_url={'/thumbnail/' + key.replace(/\.json$/, '_thumb.jpg')}
          id={key}
          key={key}
          data={data[key]}
          review_func={this.doReview}
          edit_state={this.props.frontend_settings.frontend.edit_state}
        />
      );
      list.push(item);
    }
    // save all
    var info = 'Viewing ' + visible + ' out of ' + outof + ' materials.';
    this.setState({ data_list: list, data_list_filter_info: info });
  };

  componentDidMount = () => {
    var sanity_report = appUtils.checkJSON();
    this.setState({ sanity_report: sanity_report });
  };

  refilterCallback = () => {
    this.doRefilter(this.state.data);
  };

  doRefilter = (data) => {
    for (const key in data) {
      // does it pass all filters?
      data[key]['frontend-visible'] = this.filter_ref.current.filter_approves(data[key]);
    }
    this.setState({ data: data }, () => {
      this.doRegenerate();
    });
  };

  ///////////////////
  // Helper functions
  ///////////////////

  get_selected = () => {
    if (this.state.selected_key === undefined) return null;
    if (this.state.data[this.state.selected_key] === undefined) return null;
    return this.state.data[this.state.selected_key];
  };

  /////////
  // Render
  /////////

  render = () => {
    return (
      <Switch>
        <Route path="/review/:selected_key">
          {this.render_review(true)}
          {this.render_home(false)}
        </Route>
        <Route path="/">
          {this.render_review(false)}
          {this.render_home(true)}
        </Route>
      </Switch>
    );
  };

  render_home = (visible) => {
    var is_admin = this.props.user_is_admin;
    var level = this.props.user_level;
    // if this user can release
    var can_release = false;
    var min_admin_release = this.props.frontend_settings.frontend.can_release.min_admin;
    var min_user_release = this.props.frontend_settings.frontend.can_release.min_user;
    if ((is_admin && level >= min_admin_release) || (!is_admin && level >= min_user_release)) can_release = true;
    //
    return (
      <div className={visible ? 'div-visible' : 'div-hidden'}>
        {/* SIDEBAR */}
        <div className="sidebar">
          {/* LOGO */}
          <img src={logoimage} alt="logo" className="logo" />
          {/* FILTER OR RELEASE*/}
          <div className={this.state.show_release === false ? 'div-visible' : 'div-hidden'}>
            <Filter
              filter_settings={this.props.frontend_settings.frontend.filter}
              data={this.state.data}
              ref={this.filter_ref}
              refilter_func={this.refilterCallback}
            />
          </div>
          <div className={this.state.show_release === true ? 'div-visible' : 'div-hidden'}>
            <div className="filter-wrap">
              <div className="filter-fixed">
                <div className="filter-filter">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>git status</Accordion.Header>
                      <Accordion.Body>{this.state.porcelain}</Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
              <div className="filter-scrolling">
                <div className="filter-filter">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>index.json (latest first)</Accordion.Header>
                      <Accordion.Body>{this.state.indexjson}</Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* CONTENT */}
        <div className="contentdiv">
          {/* HEADER */}
          {this.render_header()}
          {/* TOOLBAR */}
          <div className="toolbar">
            <ButtonToolbar className="mb-0" aria-label="Toolbar with Button groups">
              {!this.state.show_release && (
                <ButtonGroup className="me-4" aria-label="Clear group">
                  <Button
                    variant={'secondary shadow-none' + (this.state.show_release === true ? ' disabled' : '')}
                    onClick={(e) => this.doClearFilters(e)}
                  >
                    Clear all filters
                  </Button>{' '}
                </ButtonGroup>
              )}
              {!this.state.show_release && can_release && (
                <ButtonGroup className="me-1" aria-label="Clear group">
                  <Button variant="secondary shadow-none" onClick={(e) => this.doShowRelease(e)}>
                    Release
                  </Button>
                </ButtonGroup>
              )}
              {can_release && this.state.show_release && (
                <>
                  <ButtonGroup className="me-4" aria-label="First group">
                    <Button variant={'secondary shadow-none'} onClick={(e) => this.doShowRelease(e)}>
                      {'<< Back'}
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup className="me-2" aria-label="Clear group">
                    <Button variant="danger wide shadow-none" onClick={(e) => this.doRelease(e)}>
                      PUBLISH RELEASE
                    </Button>
                  </ButtonGroup>
                </>
              )}
            </ButtonToolbar>
            <p>{this.state.data_list_filter_info}</p>
          </div>
          {/* LIST */}
          <div className="listdiv">
            {this.state.sanity_report.length > 0 && <code>{this.state.sanity_report}</code>}
            {this.state.data_list}
          </div>
        </div>
        {/* PUBLISH MODAL */}
        <Modal show={this.state.show_publish_modal} centered>
          <Modal.Header>
            <Modal.Title>PUBLISH RELEASE</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.release_error !== '' && <Alert variant="danger">{this.state.release_error}</Alert>}
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Release name / valid git tag:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter release name"
                  value={this.state.release_name}
                  onChange={this.handleReleaseNameChange.bind(this)}
                />
                <Form.Label>Release description:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter release description"
                  value={this.state.release_description}
                  onChange={this.handleReleaseDescriptionChange.bind(this)}
                />
                <Form.Label>Valid from:</Form.Label>
                <DateTimePicker
                  className="form-control"
                  value={this.state.release_validfrom}
                  onChange={this.handleReleaseValidFromChange.bind(this)}
                />
                <Form.Label>Valid to:</Form.Label>
                <DateTimePicker
                  className="form-control"
                  value={this.state.release_validto}
                  onChange={this.handleReleaseValidToChange.bind(this)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={(e) => this.doCancelRelease()}>
              Close this dialog
            </Button>
            <Button variant="danger" onClick={(e) => this.doPublishRelease()}>
              PUBLISH RELEASE
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  render_review = (visible) => {
    var image_url = '/thumbnail/' + this.state.selected_key.replace(/\.json$/, '_thumb.jpg');
    var comments = [];
    var variants = [];
    var log = [];
    var sel_mat_name = '';
    var count_comment = '';
    var count_variants = '';
    var count_log = '';
    var es_default = this.props.frontend_settings.frontend.edit_state.default;
    var es_value = es_default;
    // is there selected item?
    // discontinued
    var discontinued = false;
    var discontinued_color = appUtils.colorDiscontinued(false);
    // Get from selected if exists
    var selected_data_item = this.get_selected();
    if (selected_data_item !== null) {
      // Get comments, log, name, discontinued
      if ('Comments' in selected_data_item) comments = selected_data_item['Comments'];
      if ('Attribs' in selected_data_item) variants = selected_data_item['Attribs'];
      if ('Log' in selected_data_item) log = selected_data_item['Log'];
      if ('Name' in selected_data_item) sel_mat_name = selected_data_item['Name'];
      if ('Discontinued' in selected_data_item) {
        discontinued = selected_data_item['Discontinued'];
        discontinued_color = appUtils.colorDiscontinued(discontinued);
      }
      if ('EditState' in selected_data_item) es_value = selected_data_item['EditState'];
    }
    count_comment = comments.length.toString() + ' ' + (comments.length === 1 ? 'comment.' : 'comments.');
    count_variants = variants.length.toString() + ' ' + (variants.length === 1 ? 'variant.' : 'variants.');
    count_log = log.length.toString() + ' ' + (log.length === 1 ? 'log record.' : 'log records.');
    // user access level variables
    var is_admin = this.props.user_is_admin;
    var level = this.props.user_level;
    // if this user can flip discontinued switch
    var can_discontinue = false;
    var min_admin_discontinue = this.props.frontend_settings.frontend.can_discontinue.min_admin;
    var min_user_discontinue = this.props.frontend_settings.frontend.can_discontinue.min_user;
    if ((is_admin && level >= min_admin_discontinue) || (!is_admin && level >= min_user_discontinue))
      can_discontinue = true;
    // if this user can modify variants
    var can_edit = false;
    var min_admin_edit = this.props.frontend_settings.frontend.can_edit.min_admin;
    var min_user_edit = this.props.frontend_settings.frontend.can_edit.min_user;
    if ((is_admin && level >= min_admin_edit) || (!is_admin && level >= min_user_edit)) can_edit = true;
    // EditState
    var es_values = this.props.frontend_settings.frontend.edit_state.values;
    var es_color = appUtils.colorEditState(0);
    var es_text = 'error';
    // es - find actual item
    var es_found = -1;
    for (var i in es_values) {
      if (es_value === es_values[i].key) {
        es_found = i;
        break;
      }
    }
    // es - if there is match set elements
    if (es_found >= 0) {
      // es - set color
      es_color = appUtils.colorEditState(es_values[es_found].colorcode);
      // es - set text
      es_text = es_values[es_found].human;
      // es - set menu
      var es_menu = [];
      var nextlist = es_values[es_found].next;
      if (nextlist.length === 0) {
        // if empty include all autoinclude
        for (var ii in es_values) {
          var itemkey = es_values[ii].key;
          var auto = es_values[ii].autoinclude;
          // skip self
          if (itemkey === es_value) continue;
          // skip non autoinclude
          if (!auto) continue;
          // add to list
          nextlist.push(itemkey);
        }
      }
      for (var iii in nextlist) {
        // convert key list to list of indexes
        var nkey = nextlist[iii];
        for (var iii1 in es_values) {
          if (nkey === es_values[iii1].key) {
            nextlist[iii] = iii1;
          }
        }
      }
      for (var n in nextlist) {
        var ind = nextlist[n];
        var human = es_values[ind].human;
        var menuitemkey = es_values[ind].key;
        var itemtoadd = (
          <Dropdown.Item key={'editstate' + menuitemkey} eventKey={menuitemkey}>
            {human}
          </Dropdown.Item>
        );
        es_menu.push(itemtoadd);
      }
      // es - can edit?
      var can_editstate = false;
      var min_admin_editstate = es_values[es_found].min_admin;
      var min_user_editstate = es_values[es_found].min_user;
      if ((is_admin && level >= min_admin_editstate) || (!is_admin && level >= min_user_editstate))
        can_editstate = true;
    }
    //
    return (
      <div className={visible ? 'div-visible' : 'div-hidden'}>
        {/* SIDEBAR */}
        <div className="sidebar">
          {/* LOGO */}
          <img src={logoimage} alt="logo" className="logo" />
          {/* MAT DETAILS */}
          <div className="filter-wrap">
            <div className="filter-fixed">
              <LazyLoadImage alt={this.props.id} height={200} width={200} src={image_url} />
              <h2>{sel_mat_name}</h2>
            </div>
            <div className="filter-fixed">
              <p>{count_comment}</p>
              <p>{count_variants}</p>
              <p>{count_log}</p>
              <p>{'Location: ' + this.state.selected_key}</p>
            </div>
          </div>
        </div>
        {/* CONTENT */}
        <div className="contentdiv">
          {/* HEADER */}
          {this.render_header()}

          <div className="toolbar">
            <ButtonToolbar className="mb-0" aria-label="Toolbar with Button groups">
              <ButtonGroup className="me-4" aria-label="First group">
                <Button variant={'secondary shadow-none'} onClick={(e) => this.doBack()}>
                  {'<< Back'}
                </Button>
              </ButtonGroup>
              <ButtonGroup className="me-4" aria-label="First group">
                <Button
                  value={'1'}
                  variant={'outline-secondary shadow-none' + (this.state.show_tab === '1' ? ' active' : '')}
                  onClick={(e) => this.doShowTab(e)}
                >
                  Comments
                </Button>{' '}
                <Button
                  value={'2'}
                  variant={'outline-secondary shadow-none' + (this.state.show_tab === '2' ? ' active' : '')}
                  onClick={(e) => this.doShowTab(e)}
                >
                  Variants
                </Button>{' '}
                <Button
                  value={'3'}
                  variant={'outline-secondary shadow-none' + (this.state.show_tab === '3' ? ' active' : '')}
                  onClick={(e) => this.doShowTab(e)}
                >
                  Log
                </Button>
              </ButtonGroup>
              <ButtonGroup className="me-4" aria-label="First group">
                <Dropdown>
                  <Dropdown.Toggle variant={discontinued_color + ' shadow-none'} id="dropdown-basic">
                    {discontinued ? 'Discontinued' : 'Included'}
                  </Dropdown.Toggle>
                  {can_discontinue && (
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={(e) => this.doDiscontinue(e)}>
                        {discontinued ? 'Re-include this material' : 'Discontinue this material'}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              </ButtonGroup>

              <ButtonGroup className="me-4" aria-label="First group">
                <Dropdown onSelect={(e) => this.doChangeEditState(e)}>
                  <Dropdown.Toggle variant={es_color + ' shadow-none'} id="dropdown-basic">
                    {es_text}
                  </Dropdown.Toggle>
                  {can_editstate && <Dropdown.Menu>{es_menu}</Dropdown.Menu>}
                </Dropdown>
              </ButtonGroup>
            </ButtonToolbar>
          </div>

          {/* LIST */}
          <PageReview
            selected_key={this.state.selected_key}
            selected_hex_id={this.state.selected_hex_id}
            history={this.props.history}
            comments={comments}
            log={log}
            send_func={this.props.send_func}
            on_event={this.on_event}
            user_name={this.props.user_name}
            user_is_admin={this.props.user_is_admin}
            user_level={this.props.user_level}
            by_id_func={this.doReviewById}
            show_tab={this.state.show_tab}
            edits={this.state.edits}
            data={selected_data_item}
            can_edit={can_edit}
          />
        </div>
      </div>
    );
  };

  render_header = () => {
    return (
      <div className="header">
        <div className="headerlogininfo">
          {'Logged In as "' + this.props.user_name + '"'}
          <span>
            <Button className="logoutButton" variant="secondary" size="sm" onClick={(e) => this.props.logout_func()}>
              Logout
            </Button>
          </span>
        </div>
      </div>
    );
  };

  ///////////
  // ON_EVENT
  ///////////

  on_event = (ev) => {
    // if selected item doesn't exist just exit
    var selected_data_item = this.get_selected();
    if (selected_data_item === null) return;
    // if it is not in the list just exit
    var auto = this.props.frontend_settings.frontend.automatic;
    if (!(ev in auto)) return;
    // select event id depending on admin/user
    var is_admin = this.props.user_is_admin;
    var event = auto[ev][is_admin ? 0 : 1];
    // if empty just exit
    if (event === '') return;
    // get current EditState
    var es = '';
    if ('EditState' in selected_data_item) es = selected_data_item['EditState'];
    // if they are the same just exit
    if (es === event) return;
    // set new value
    var es_values = this.props.frontend_settings.frontend.edit_state.values;
    var es_found = -1;
    for (var i in es_values) {
      if (event === es_values[i].key) {
        es_found = i;
        break;
      }
    }
    if (es_found < 0) return;
    var human = es_values[es_found].human;
    //
    let json = { editstate: event, for: this.state.selected_key, human: human, manual: false };
    this.props.send_func(json);
  };

  /////
  // UI
  /////

  doChangeEditState = (e) => {
    // find actual item
    var editstate = e;
    var es_values = this.props.frontend_settings.frontend.edit_state.values;
    var es_found = -1;
    for (var i in es_values) {
      if (editstate === es_values[i].key) {
        es_found = i;
        break;
      }
    }
    if (es_found < 0) return;
    var human = es_values[es_found].human;
    //
    let json = { editstate: editstate, for: this.state.selected_key, human: human, manual: true };
    this.props.send_func(json);
  };

  doDiscontinue = (e) => {
    e.preventDefault();
    var discontinued = false;
    var selected_data_item = this.get_selected();
    if (selected_data_item === null) return;
    if ('Discontinued' in selected_data_item) discontinued = selected_data_item['Discontinued'];
    let json = { discontinued: !discontinued, for: this.state.selected_key };
    this.props.send_func(json);
  };

  doShowTab = (e) => {
    e.preventDefault();
    var d = e.target.value;
    this.setState({ show_tab: d });
  };

  doReview = (selected_key) => {
    // disable review in show_release
    if (this.state.show_release) return;
    // otherwise review material
    var selected_hex_id = this.state.data[selected_key]['frontend-hex-id'];
    this.setState({ selected_key: selected_key, selected_hex_id: selected_hex_id, show_tab: '1' }, () => {
      this.on_event('on_review');
      this.props.history.push('/review/' + selected_hex_id);
    });
  };

  doReviewById = (hex_id) => {
    // find key for frontend-hex-id
    var found_id = '';
    for (var key of Object.keys(this.state.data)) {
      if (this.state.data[key]['frontend-hex-id'] === hex_id) {
        found_id = key;
        break;
      }
    }
    // if not found redirect to /
    if (found_id === '') {
      this.props.history.push('/');
      return;
    }
    // if found call doReview to load data
    this.doReview(found_id);
  };

  doBack = () => {
    this.props.history.push('/');
  };

  doClearFilters = () => {
    this.filter_ref.current.clear_all_filters();
  };

  /////////////
  // Release!!!
  /////////////

  received_new_release_report = (result) => {
    // currently output to console only
    console.log('------------- RELEASE REPORT START -------------');
    console.log(result);
    console.log('------------- RELEASE REPORT END -------------');
  };

  received_new_porcelain = (result) => {
    var porcelain;
    if (result.length < 1) {
      porcelain = (
        <Row>
          <Col>Not a git repository!</Col>
        </Row>
      );
      this.setState({ porcelain: porcelain });
      return;
    }
    var lines = result.split('\n');
    // head
    var head = 'unknown';
    for (var h in lines) {
      if (lines[h].indexOf('# branch.head ') !== -1) {
        head = lines[h].replace(/# branch.head /g, '');
      }
    }
    // upstream
    var upstream = 'unknown';
    for (var u in lines) {
      if (lines[u].indexOf('# branch.upstream ') !== -1) {
        upstream = lines[u].replace(/# branch.upstream /g, '');
      }
    }
    // lines
    var changed = 0; //1
    var moved = 0; //2
    var unmerged = 0; //u
    var untracked = 0; //?
    var ignored = 0; //!
    for (var i in lines) {
      var line = lines[i];
      if (line.length < 1) continue;
      var char = line[0];
      if (char === '#') continue;
      if (char === '1') changed++;
      if (char === '2') moved++;
      if (char === 'u') unmerged++;
      if (char === '?') untracked++;
      if (char === '!') ignored++;
    }
    porcelain = (
      <Row>
        <Col>Head:</Col>
        <Col xs={7}>{head}</Col>
        <Col>Upstream:</Col>
        <Col xs={7}>{upstream}</Col>
        {changed > 0 && (
          <>
            <Col>Changed:</Col>
            <Col xs={7}>{changed}</Col>
          </>
        )}
        {moved > 0 && (
          <>
            <Col>Moved:</Col>
            <Col xs={7}>{moved}</Col>
          </>
        )}
        {unmerged > 0 && (
          <>
            <Col>Unmerged:</Col>
            <Col xs={7}>{unmerged}</Col>
          </>
        )}
        {untracked > 0 && (
          <>
            <Col>Untracked:</Col>
            <Col xs={7}>{untracked}</Col>
          </>
        )}
        {ignored > 0 && (
          <>
            <Col>Ignored:</Col>
            <Col xs={7}>{ignored}</Col>
          </>
        )}
      </Row>
    );
    this.setState({ porcelain: porcelain });
  };

  received_new_indexjson = (result) => {
    var indexjson;
    // if empty report error
    if (result.length < 1) {
      indexjson = (
        <Row key="indexjsonerror">
          <Col>Missing index.json file!</Col>
        </Row>
      );
      this.setState({ indexjson: indexjson });
      return;
    }
    // otherwise process received data
    indexjson = [];
    var j = JSON.parse(result)['releases'];
    for (var i = j.length - 1; i >= 0; i--) {
      var item = j[i];
      var name = item['name'];
      var description = item.description;
      var validfrom = item['valid_from'];
      var validfromdate = new Date(validfrom * 1000);
      var validfromdatestr = validfromdate.toLocaleDateString() + ', ' + validfromdate.toLocaleTimeString();
      var validto = item['valid_to'];
      var validtodate = new Date(validto * 1000);
      var validtodatestr = validtodate.toLocaleDateString() + ', ' + validtodate.toLocaleTimeString();
      var itemtoadd = (
        <div key={'indexjsonwrap' + i}>
          {i < j.length - 1 && <hr />}
          <Row key={'indexjson 01 ' + i}>
            <Col>
              <b>{'Release'}</b>
              {': ' + name}
            </Col>
          </Row>
          <Row key={'indexjson 02 ' + i}>
            <Col xs={1}> </Col>
            <Col>
              <b>{'Description'}</b>
              {': ' + description}
            </Col>
          </Row>
          <Row key={'indexjson 03 ' + i}>
            <Col xs={1}> </Col>
            <Col>
              <b>{'Valid from'}</b>
              {': ' + validfromdatestr}
            </Col>
          </Row>
          <Row key={'indexjson 04 ' + i}>
            <Col xs={1}> </Col>
            <Col>
              <b>{'Valid to'}</b>
              {': ' + validtodatestr}
            </Col>
          </Row>
        </div>
      );
      indexjson.push(itemtoadd);
    }
    this.setState({ indexjson: indexjson });
  };

  doShowRelease = (e) => {
    var newval = !this.state.show_release;
    this.setState({ show_release: newval, porcelain: '' }, () => {
      if (newval) {
        let json = { porcelain: true };
        this.props.send_func(json);
      }
    });
  };

  doRelease = (e) => {
    this.setState({
      show_publish_modal: true,
      release_name: '',
      release_description: '',
      release_validfrom: new Date(),
      release_validto: new Date(),
      release_error: ''
    });
  };

  doCancelRelease = (e) => {
    this.setState({ show_publish_modal: false });
  };

  doPublishRelease = (e) => {
    // name
    var relname = this.state.release_name.trim();
    if (relname === '') {
      this.setState({ release_error: 'Name is empty.' });
      return;
    }
    // description
    var reldesc = this.state.release_description.trim();
    if (reldesc === '') {
      this.setState({ release_error: 'Description is empty.' });
      return;
    }
    // from date
    var relfrom = this.state.release_validfrom;
    if (relfrom === null) {
      this.setState({ release_error: 'Valid from is not defined.' });
      return;
    }
    relfrom = Math.round(relfrom.getTime() / 1000);
    // to date
    var relto = this.state.release_validto;
    if (relto === null) {
      this.setState({ release_error: 'Valid to is not defined.' });
      return;
    }
    relto = Math.round(relto.getTime() / 1000);

    // close modal
    this.setState({ show_publish_modal: false });
    // calling publish release here!
    let json = { publishrelease: relname, description: reldesc, from: relfrom, to: relto };
    this.props.send_func(json);
  };

  handleReleaseNameChange = (e) => {
    this.setState({ release_name: e.target.value });
  };

  handleReleaseDescriptionChange = (e) => {
    this.setState({ release_description: e.target.value });
  };

  handleReleaseValidFromChange = (e) => {
    this.setState({ release_validfrom: e });
  };

  handleReleaseValidToChange = (e) => {
    this.setState({ release_validto: e });
  };
}

export default PageApplication;
