import React, { Component } from 'react';
import './Filter.css';

import { Form, Accordion } from 'react-bootstrap';

import * as filterUtils from '../filter-utils';

class Check extends Component {
  constructor(props) {
    super(props);
    this.form_ref = React.createRef();
    this.state = {
      selected: [],
      item_list: []
    };
  }

  render = () => {
    var should_open = false;
    if ('open' in this.props.settings) should_open = this.props.settings.open;
    return (
      <div className="filter-filter">
        <Accordion defaultActiveKey={should_open ? '0' : 'none'}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>{this.props.settings.title}</Accordion.Header>
            <div className="filter-body">
              <Accordion.Body>
                <Form ref={this.form_ref}>{this.state.item_list}</Form>
              </Accordion.Body>
            </div>
          </Accordion.Item>
        </Accordion>
      </div>
    );
  };

  handleChange = (e) => {
    // store filter value
    var v = e.target.value;
    var sel = this.state.selected;
    if (e.target.checked) {
      sel.push(v);
    } else {
      sel = sel.filter((item) => item !== v);
    }
    this.setState({ selected: sel }, () => {
      this.props.refilter_func();
    });
  };

  componentDidMount = () => {
    this.filter_update_values();
  };

  filter_approves = (item) => {
    // none selected is considered as all selected
    if (this.state.selected.length <= 0) return true;
    // actually test filter values
    var list = filterUtils.item_values(item, this.props.settings.keys, this.props.settings.replace);
    for (var selitem of this.state.selected) {
      if (list.indexOf(selitem) >= 0) {
        return true;
      }
    }
    return false;
  };

  filter_update_values = () => {
    var item_type = 'checkbox';
    var item_list = [];
    var list = [];
    if ('list' in this.props.settings) list = this.props.settings.list;
    if (list.length <= 0)
      list = filterUtils.get_data_values(this.props.data, this.props.settings.keys, this.props.settings.replace);
    for (var item of list) {
      item_list.push(
        <Form.Check key={item_type + this.props.settings.title + item}>
          <Form.Check.Input
            type={item_type}
            id={item_type + this.props.settings.title + item}
            value={item}
            name={item_type + this.props.settings.title}
            onChange={this.handleChange.bind(this)}
          />
          <Form.Check.Label htmlFor={item_type + this.props.settings.title + item}>{item}</Form.Check.Label>
        </Form.Check>
      );
    }
    this.setState({ item_list: item_list });
  };

  filter_clear = () => {
    if (this.state.selected !== []) {
      this.setState({ selected: [] }, () => {
        this.form_ref.current.reset();
        this.props.refilter_func();
      });
    }
  };
}

export default Check;
