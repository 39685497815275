import React, { Component } from 'react';

import { Container } from 'react-bootstrap';

import logoimage from '../resources/logo.png';
import spinnerimage from '../resources/loading.gif';

class PageWaiting extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    return (
      <Container>
        <div className="loadingcontainer">
          <div className="centeredxy">
            <img src={logoimage} alt="logo" className="logo" />
            <img src={spinnerimage} alt="spinner" className="spinner" />
          </div>
        </div>
      </Container>
    );
  };
}

export default PageWaiting;
