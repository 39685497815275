/////////////////////////////////
// Switch between different modes
/////////////////////////////////

const appStateEnum = {
  appStateInitial: 0, //Not logged in, not showing login dialog, about to send login on ws connect
  appStateSent: 1, //Sent credentials, either token or un/pw, waiting for response
  appStateShowLogin: 2, //Credentials failed, showing login dialog, ends with appStateSent again
  //Next two can be moved to URL paths later on
  appStateLoggedInSelector: 3, //Logged in, show selector
  appStateLoggedInDetails: 4 //Logged in, show details
};

///////////////////////////////
// Get settings.json for client
///////////////////////////////

const settingsJSON = () => {
  // Push default if there is no variable
  var jsonstr;
  if (process.env.REACT_APP_SETTINGS) {
    jsonstr = process.env.REACT_APP_SETTINGS;
  } else {
    // we have to have this variable even in yarn build
    window.alert('REACT_APP_SETTINGS?');
  }
  return JSON.parse(jsonstr);
};

/////////////////////////////
// settings.json sanity check
/////////////////////////////

const checkJSON = () => {
  var json = settingsJSON();
  //return JSON.stringify(json);
  var error_report = '';
  // pick up all available keys, probably faster to store in object than list
  var keys = {};
  var list = json.frontend.edit_state.values;
  for (var i in list) {
    var k = list[i].key;
    keys[k] = false;
  }
  // check if default exists
  var d = json.frontend.edit_state.default;
  if (!(d in keys)) {
    error_report += '* settings.json error: Default key "' + d + '" does not exist.\n';
  }
  // check if all 'next' keys exist
  for (var j in list) {
    var knext = list[j].next;
    for (var ii in knext) {
      var kn = knext[ii];
      if (!(kn in keys)) {
        error_report += '* settings.json error: Next key "' + kn + '" does not exist.\n';
      }
    }
  }
  // check if every key can be jumped to next at least once
  for (var n in list) {
    var kk = list[n].key;
    var found = false;
    for (var n1 in list) {
      // skip checking next on self
      if (n === n1) continue;
      // actual check
      var knext1 = list[n1].next;
      if (knext1.length <= 0) {
        found = true;
        break;
      }
      for (var iii in knext1) {
        if (knext1[iii] === kk) {
          found = true;
          break;
        }
      }
    }
    if (!found) {
      error_report += '* settings.json error: Key "' + kk + '" is not referenced in any next option.\n';
    }
  }
  // check if automatic keys exist
  var a = json.frontend.automatic;
  for (var aa in a) {
    for (var aaa in a[aa]) {
      var ajump = a[aa][aaa];
      if (ajump !== '') {
        if (!(ajump in keys)) {
          error_report += '* settings.json error: Automatic referenced key "' + ajump + '" does not exist.\n';
        }
      }
    }
  }
  //
  return error_report;
};

////////////////////////////
// http API (if we need any)
////////////////////////////

const apiBaseURL = () => {
  var url = settingsJSON().api_base_url;
  if (!url.endsWith('/')) url += '/';
  return url;
};

////////////
// WS access
////////////

const wsBaseURL = () => {
  var url = settingsJSON().ws_base_url;
  if (!url.endsWith('/')) url += '/';
  return url;
};

///////////
// App name
///////////

const appName = () => {
  var name = settingsJSON().app;
  return name;
};

//////////////////////
// Discontinued colors
//////////////////////

const colorDiscontinued = (flag) => {
  return flag ? 'danger' : 'success';
};

///////////////////
// EditState colors
///////////////////

const colorEditState = (colindex) => {
  var es_color = '';
  switch (colindex) {
    // 0 - initial black
    case 0:
      es_color = 'secondary';
      break;
    // 1 - notify us
    case 1:
      es_color = 'primary';
      break;
    // 2 - notify them
    case 2:
      es_color = 'success';
      break;
    // 3 - notify all
    case 3:
      es_color = 'warning';
      break;
    // 4 - ending black
    default:
      es_color = 'dark';
      break;
  }
  return es_color;
};

// Export

export { settingsJSON, checkJSON, apiBaseURL, wsBaseURL, appName, appStateEnum, colorDiscontinued, colorEditState };
