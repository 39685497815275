import React, { Component } from 'react';

import { Form, Button, Modal } from 'react-bootstrap';

import EditGlobal from '../components/EditGlobal';
import EditCard from '../components/EditCard';

//this.props.edits
//this.props.data

class Variants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      variant_filter_value: '',
      variants: [],
      variant_cards: [],
      filter_status: '',
      dlg_delete_show_flag: false,
      uuid_to_process: '',
      id_ui_data: [],
      dlg_edit_show_flag: false,
      id_edit_data: {},
      id_edit_editable: [],
      id_edit_ui: []
    };
  }

  render = () => {
    return (
      <>
        <div className="edit-block">
          <div className="edit-left-block">
            <div className="edit-left-block-top">
              <h4>Filter</h4>
              <div className="filter-body">
                <Form.Control
                  type="text"
                  placeholder="Filter..."
                  value={this.state.variant_filter_value}
                  onChange={this.handleChange.bind(this)}
                  className="filter-edit shadow-none"
                />
                <p>{this.state.filter_status}</p>
              </div>
            </div>
            <div className="edit-left-block-bottom">
              <EditGlobal
                edits={this.props.edits}
                data={this.props.data}
                send_func={this.props.send_func}
                selected_key={this.props.selected_key}
                can_edit={this.props.can_edit}
                on_event={this.props.on_event}
              />
            </div>
          </div>
          <div className="edit-right-block">
            <div className="edit-segment-tools">
              <h4>
                Variants{'  '}
                {this.props.can_edit && (
                  <span>
                    <Button variant="primary shadow-none" size="sm" onClick={this.create_new_variant}>
                      Create new variant
                    </Button>
                  </span>
                )}
              </h4>
            </div>
            <div className="edit-segment-cards">{this.state.variant_cards}</div>
          </div>
        </div>
        <Modal show={this.state.dlg_delete_show_flag} onHide={this.dlg_delete_close}>
          <Modal.Header closeButton>
            <Modal.Title>Delete variant?</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.id_ui_data}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.dlg_delete_close}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.dlg_delete_confirm}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.dlg_edit_show_flag} onHide={this.dlg_edit_close}>
          <Modal.Header closeButton>
            <Modal.Title>Edit variant</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.id_edit_ui}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.dlg_edit_close}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.dlg_edit_confirm}>
              Save changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    //update variants if needed
    if (this.props.data !== null) {
      if (JSON.stringify(this.props.data.Attribs) !== JSON.stringify(this.state.variants)) {
        this.setState({ variants: this.props.data.Attribs }, () => {
          this.generate_variant_cards();
        });
      }
    }
  }

  componentDidMount = () => {};

  // edits and UI

  handleChange = (e) => {
    // store filter value
    var v = e.target.value;
    this.setState({ variant_filter_value: v }, () => {
      this.generate_variant_cards();
    });
  };

  // handling variants

  generate_variant_cards = () => {
    var vlist = [];
    var included = 0;
    var total = 0;
    for (var x of this.state.variants) {
      // filter
      var to_include = true;
      if (this.state.variant_filter_value !== '') {
        var item_value = '';
        for (var key in x) {
          item_value += x[key].toUpperCase() + ' ';
        }
        if (item_value.indexOf(this.state.variant_filter_value.toUpperCase()) === -1) {
          to_include = false;
        }
      }
      if (to_include) {
        included++;
        // generate card
        vlist.push(
          <EditCard
            key={'editcard' + total.toString()}
            edits={this.props.edits}
            carddata={x}
            uuid={x['UUID']}
            delete_func={this.delete_proc}
            edit_func={this.edit_proc}
            can_edit={this.props.can_edit}
          />
        );
      }
      total++;
    }
    // generate filter status
    var fs = '(Viewing ' + included.toString() + ' out of ' + total.toString() + ' variant';
    if (total !== 1) {
      fs += 's)';
    } else {
      fs += ')';
    }
    // set values
    this.setState({ variant_cards: vlist, filter_status: fs });
  };

  // delete dialog

  delete_proc = (uuid, id_ui_data) => {
    this.setState({ id_ui_data: id_ui_data, uuid_to_process: uuid, dlg_delete_show_flag: true });
  };

  dlg_delete_close = () => {
    this.setState({ dlg_delete_show_flag: false });
  };

  dlg_delete_confirm = () => {
    this.setState({ dlg_delete_show_flag: false });
    let json = { deletevariant: this.state.uuid_to_process, for: this.props.selected_key };
    this.props.send_func(json);
    this.props.on_event('on_variant_delete');
  };

  // edit dialog

  create_new_variant = () => {
    this.edit_proc('new', {});
  };

  edit_proc = (uuid, id_data) => {
    this.setState({ id_edit_data: id_data, uuid_to_process: uuid, dlg_edit_show_flag: true }, () => {
      this.update_edit_ui();
    });
  };

  update_edit_ui = () => {
    // Complex but we need to build UI that depends on Model:
    // id_edit_data {} holds key: value edit values
    // id_edit_editable [] holds ordered array of [{id: key, name: str, values: {key: value}}] for all relevant fields, empty is readonly
    // on save we need to clean up id_edit_data
    //
    // fetch all state variables
    var id_edit_data = Object.assign({}, this.state.id_edit_data);
    var id_edit_editable = [];
    // Name has to exist, readonly
    if (!('Name' in id_edit_data)) {
      var name = 'error';
      if ('Name' in this.props.data) name = this.props.data['Name'];
      id_edit_data['Name'] = name;
    }
    id_edit_editable.push({ id: 'Name', name: 'Name', values: {} });
    // Model has to exist, editable, add first if missing
    if (!('Model' in id_edit_data)) {
      var model = this.props.edits.edits.products[0].id;
      id_edit_data['Model'] = model;
    }
    var model_values = {};
    for (var mod in this.props.edits.edits.products) {
      model_values[this.props.edits.edits.products[mod].id] = this.props.edits.edits.products[mod].name;
    }
    id_edit_editable.push({ id: 'Model', name: 'Model', values: Object.assign({}, model_values) });
    // add all 'top'
    for (var f in this.props.edits.edits.filters) {
      if (this.props.edits.edits.filters[f].location === 'top') {
        var top_id = this.props.edits.edits.filters[f].id;
        var top_name = this.props.edits.edits.filters[f].name;
        var top_values = {};
        for (var v in this.props.edits.edits.filters[f].values) {
          top_values[this.props.edits.edits.filters[f].values[v]] = this.props.edits.edits.filters[f].values[v];
        }
        // we could easily allow 'top' edits here, for now just show them
        //id_edit_editable.push({ id: top_id, name: top_name, values: Object.assign({}, top_values) });
        id_edit_editable.push({ id: top_id, name: top_name, values: {} });
        // add default if missing
        if (!(top_id in id_edit_data)) {
          var top_default = '<error>';
          if (top_id in this.props.data) top_default = this.props.data[top_id][0];
          id_edit_data[top_id] = top_default;
        }
      }
    }
    // add all 'attrib' for that Model, add missing defaults
    var target_model = id_edit_data['Model'];
    for (var ff in this.props.edits.edits.filters) {
      if (this.props.edits.edits.filters[ff].location === 'attrib') {
        // 'for' list of Models in each filter determines if filter/edit applies to model
        var filter_models = [];
        if ('for' in this.props.edits.edits.filters[ff]) filter_models = this.props.edits.edits.filters[ff].for;
        var applies = false;
        if (filter_models.length > 0) {
          for (var i in filter_models) {
            if (target_model === filter_models[i]) {
              applies = true;
              break;
            }
          }
        } else {
          applies = true; // FIXME: false for production to require 'for' spec for each filter
        }
        if (applies) {
          var attrib_id = this.props.edits.edits.filters[ff].id;
          var attrib_name = this.props.edits.edits.filters[ff].name;
          var attrib_values = {};
          for (var vv in this.props.edits.edits.filters[ff].values) {
            attrib_values[this.props.edits.edits.filters[ff].values[vv]] =
              this.props.edits.edits.filters[ff].values[vv];
          }
          id_edit_editable.push({ id: attrib_id, name: attrib_name, values: Object.assign({}, attrib_values) });
          // add first as default if missing
          if (!(attrib_id in id_edit_data)) {
            var attrib_default = Object.keys(attrib_values)[0];
            id_edit_data[attrib_id] = attrib_default;
          }
        }
      }
    }
    // past this point:
    //     id_edit_data is storing key: value for each edit
    //     id_edit_editable is storing order, id, name and optional values for each edit
    // build UI, either show text or offer <select>
    var id_edit_ui = [];
    for (var ui in id_edit_editable) {
      var ui_values = id_edit_editable[ui].values;
      var ui_editable = Object.keys(ui_values).length > 0;
      var ui_title = id_edit_editable[ui].name;
      var ui_id = id_edit_editable[ui].id;
      var ui_value = id_edit_data[ui_id];
      if (!ui_editable) {
        // add text
        id_edit_ui.push(
          <label key={'editlabel_' + ui_id}>
            {ui_title + ':'}
            <div>
              <b>{ui_value}</b>
            </div>
          </label>
        );
      } else {
        // pack options
        var ui_options = [];
        var optkeys = Object.keys(ui_values);
        for (var optind in optkeys) {
          var optkey = optkeys[optind];
          var optvalue = ui_values[optkey];
          ui_options.push(
            <option value={optkey} key={'option' + ui_id + optkey}>
              {optvalue}
            </option>
          );
        }
        // add <select>
        id_edit_ui.push(
          <label key={'editlabel_' + ui_id}>
            {ui_title + ':'}
            <select key={'editselect_' + ui_id} value={ui_value} onChange={this.edit_change} id={ui_id}>
              {ui_options}
            </select>
          </label>
        );
      }
    }
    //alert(JSON.stringify(id_edit_ui, null, 4));
    // set state id_edit_data, id_edit_editable, id_edit_ui
    this.setState({ id_edit_data: id_edit_data, id_edit_editable: id_edit_editable, id_edit_ui: id_edit_ui });
  };

  edit_change = (e) => {
    var e_id = e.currentTarget.id;
    var e_v = e.target.value;
    var id_edit_data = this.state.id_edit_data;
    id_edit_data[e_id] = e_v;
    this.setState({ id_edit_data: id_edit_data }, () => {
      this.update_edit_ui();
    });
  };

  dlg_edit_close = () => {
    this.setState({ dlg_edit_show_flag: false });
  };

  dlg_edit_confirm = () => {
    // clean up id_edit_data using id_edit_editable
    var final = {};
    for (var ui in this.state.id_edit_editable) {
      var ui_id = this.state.id_edit_editable[ui].id;
      final[ui_id] = this.state.id_edit_data[ui_id];
    }
    // always needs uuid
    final['UUID'] = this.state.uuid_to_process;
    // save!
    this.setState({ dlg_edit_show_flag: false });
    let json = {
      updatevariant: this.state.uuid_to_process,
      for: this.props.selected_key,
      content: JSON.stringify(final)
    };
    this.props.send_func(json);
    if (this.state.uuid_to_process === 'new') {
      this.props.on_event('on_variant_new');
    } else {
      this.props.on_event('on_variant_edit');
    }
  };
}

export default Variants;
