import React, { Component } from 'react';

import { Card, Button, Row, Col } from 'react-bootstrap';

class EditCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carddata: [],
      card_ui: []
    };
  }

  render = () => {
    return (
      <Card className="card-wide">
        <Card.Body>
          {/*<p>{this.props.uuid}</p>*/}
          <div className="card-ui">{this.state.card_ui}</div>
          <div className="card-footer">
            {this.props.can_edit && (
              <>
                <Button variant="outline-danger shadow-none" size="sm" onClick={(e) => this.doDelete()}>
                  Delete
                </Button>
                <Button variant="outline-primary shadow-none" size="sm" onClick={(e) => this.doEdit()}>
                  Edit
                </Button>
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    );
  };

  componentDidUpdate(prevProps) {
    this.updateIfNeeded();
  }

  componentDidMount = () => {
    this.updateIfNeeded();
  };

  updateIfNeeded = () => {
    //update variants if needed
    if (this.props.carddata !== null) {
      if (JSON.stringify(this.props.carddata) !== JSON.stringify(this.state.carddata)) {
        this.setState({ carddata: this.props.carddata }, () => {
          this.generate_card_ui();
        });
      }
    }
  };

  doDelete = () => {
    this.props.delete_func(this.props.uuid, this.state.card_ui);
  };

  doEdit = () => {
    this.props.edit_func(this.props.uuid, this.state.carddata);
  };

  generate_card_ui = () => {
    var clist = [];
    var cindex = 0;
    var human_title = '';
    var human_value = '';
    for (var x in this.state.carddata) {
      // skip UUID
      if (x === 'UUID') continue;
      // title: find human title and value if they exist
      switch (x) {
        case 'Name': // Name is always name
          human_title = x;
          human_value = this.state.carddata[x];
          break;
        case 'Model': // Model requires looking up
          human_title = x;
          var lookup = this.state.carddata[x];
          human_value = lookup + '<error>';
          for (var mod in this.props.edits.edits.products) {
            if (this.props.edits.edits.products[mod].id === lookup) {
              human_value = this.props.edits.edits.products[mod].name;
            }
          }
          break;
        default:
          // regular filters, lookup title and literal value
          human_title = x + '<error>';
          human_value = this.state.carddata[x];
          for (var f in this.props.edits.edits.filters) {
            if (this.props.edits.edits.filters[f].id === x) {
              human_title = this.props.edits.edits.filters[f].name;
              break;
            }
          }
      }
      // generate row
      clist.push(
        <Row key={'cardrow' + cindex.toString()} className={'card-left'}>
          <Col>{human_title}:</Col>
          <Col xs={7}>
            <b>{human_value}</b>
          </Col>
        </Row>
      );
      cindex += 1;
    }
    this.setState({ card_ui: clist });
  };
}

export default EditCard;
