import React, { Component } from 'react';

import {} from 'react-bootstrap';

import EditTop from '../components/EditTop';

class EditGlobal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edits: [],
      data: [],
      edit_list: [],
      selected_key: ''
    };
  }

  render = () => {
    return (
      <>
        <h4>Global</h4>
        {this.state.edit_list}
      </>
    );
  };

  componentDidUpdate(prevProps) {
    this.updateIfNeeded();
  }

  componentDidMount = () => {
    this.updateIfNeeded();
  };

  updateIfNeeded = () => {
    //update global edits if needed
    if (this.props.edits !== null) {
      if (JSON.stringify(this.props.edits) !== JSON.stringify(this.state.edits)) {
        this.setState({ edits: this.props.edits }, () => {
          this.generate_edits();
        });
      }
    }
    if (this.props.data !== null) {
      if (JSON.stringify(this.props.data) !== JSON.stringify(this.state.data)) {
        this.setState({ data: this.props.data }, () => {
          this.generate_edits();
        });
      }
    }
    if (this.state.selected_key !== this.props.selected_key) {
      this.setState({ selected_key: this.props.selected_key }, () => {
        this.generate_edits();
      });
    }
  };

  generate_edits = () => {
    var edit_list = [];
    var total = 0;
    for (var x in this.state.edits.edits.filters) {
      var f = this.state.edits.edits.filters[x];
      if (f.location !== 'top') continue;
      edit_list.push(
        <EditTop
          key={'edittop' + total.toString()}
          data={this.props.data}
          name={f.name}
          item={f}
          id={f.id}
          send_func={this.props.send_func}
          selected_key={this.state.selected_key}
          can_edit={this.props.can_edit}
          on_event={this.props.on_event}
        />
      );
      total++;
    }
    this.setState({ edit_list: edit_list });
  };
}

export default EditGlobal;
