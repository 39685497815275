import React, { Component } from 'react';
import './Filter.css';

import { Form } from 'react-bootstrap';

import * as filterUtils from '../filter-utils';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit_value: ''
    };
  }

  render = () => {
    return (
      <div className="filter-filter">
        <div className="filter-title">{this.props.settings.title}</div>
        <div className="filter-body">
          <Form.Control
            type="text"
            placeholder={this.props.settings.placeholder}
            value={this.state.edit_value}
            onChange={this.handleChange.bind(this)}
            className="filter-edit shadow-none"
          />
        </div>
      </div>
    );
  };

  handleChange = (e) => {
    // store filter value
    var v = e.target.value;
    this.setState({ edit_value: v }, () => {
      this.props.refilter_func();
    });
  };

  filter_approves = (item) => {
    // empty search string?
    if (this.state.edit_value === '') return true;
    // actually test filter values
    var list = filterUtils.item_values(item, this.props.settings.keys);
    var search_target = '^' + list.join('^');
    // if field_start or word_start requires search to start with terminator
    var search_for = this.state.edit_value;
    if (this.props.settings.field_start || this.props.settings.word_start) {
      search_for = '^' + search_for;
    }
    // if word_start fill in spaces with terminator
    if (this.props.settings.word_start) {
      search_target = search_target.replace(/ /gi, '^');
    }
    // if case_sensitive upcase both
    if (!this.props.settings.case_sensitive) {
      search_target = search_target.toUpperCase();
      search_for = search_for.toUpperCase();
    }
    // compare
    return search_target.includes(search_for);
  };

  componentDidMount = () => {};

  filter_update_values = () => {};

  filter_clear = () => {
    if (this.state.edit_value !== '') {
      this.setState({ edit_value: '' }, () => {
        this.props.refilter_func();
      });
    }
  };
}

export default Search;
