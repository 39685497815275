import React, { Component } from 'react';

import { Button, Table, InputGroup, FormControl } from 'react-bootstrap';

import Variants from '../components/Variants';

class PageReview extends Component {
  constructor(props) {
    super(props);
    this.chat_ref = React.createRef();
    this.log_ref = React.createRef();
    this.state = {
      comment: '',
      comments_list: [],
      comments: [],
      log_list: [],
      log: []
    };
  }

  render = () => {
    return (
      <div className="detailsdiv">
        <div className="detailswrap">
          {/* 1st tab, chat */}
          <div className={this.props.show_tab === '1' ? 'div-visible' : 'div-hidden'}>
            <div className="commentsblock">
              {this.state.comments_list}
              <div className="bubblesend" ref={this.chat_ref}></div>
            </div>
            <div className="commentedit">
              <InputGroup className="mb-0">
                <FormControl
                  type="text"
                  placeholder="Enter comment"
                  value={this.state.comment}
                  onChange={this.handleCommentChange.bind(this)}
                  aria-label="comment"
                  aria-describedby="comment"
                  className="shadow-none"
                />
                <Button variant="primary shadow-none" id="buttonsentcomment" onClick={(e) => this.handleCommentPost()}>
                  Send comment
                </Button>
              </InputGroup>
            </div>
          </div>

          {/* 2nd tab, edits */}
          <div className={this.props.show_tab === '2' ? 'div-visible' : 'div-hidden'}>
            <Variants
              edits={this.props.edits}
              data={this.props.data}
              send_func={this.props.send_func}
              selected_key={this.props.selected_key}
              can_edit={this.props.can_edit}
              on_event={this.props.on_event}
            />
          </div>

          {/* 3rd tab, log */}
          <div className={this.props.show_tab === '3' ? 'div-visible' : 'div-hidden'}>
            <div className="logblock">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>User</th>
                    <th>Event</th>
                  </tr>
                </thead>
                <tbody>{this.state.log_list}</tbody>
              </Table>
              <div className="bubblesend" ref={this.log_ref}></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  componentDidMount = () => {
    this.redirect_if_needed();
    this.update_comments_if_needed();
    this.update_log_if_needed();
    if (this.props.history.location.pathname === '/review' && this.props.selected_key === '') {
      this.props.history.push('/');
    } else {
      this.update_comments();
      this.update_log();
    }
  };

  componentDidUpdate(prevProps) {
    this.redirect_if_needed();
    this.update_comments_if_needed();
    this.update_log_if_needed();
  }

  redirect_if_needed = () => {
    // if it is / just skip everything else
    if (this.props.history.location.pathname === '/') return;
    // gather values
    var url_hex_id = '';
    if (this.props.history.location.pathname.includes('/review/')) {
      url_hex_id = this.props.history.location.pathname.replace(/^\/review\//, '');
    }
    var props_hex_id = this.props.selected_hex_id;
    // url_hex_id must exist, if missing redirect to /
    if (url_hex_id === '') {
      this.props.history.push('/');
      return;
    }
    // if they differ request new data
    if (url_hex_id !== props_hex_id) {
      this.props.by_id_func(url_hex_id);
      return;
    }
    // at this point everything is ok and no redirection needed
  };

  update_comments_if_needed = () => {
    if (JSON.stringify(this.props.comments) !== JSON.stringify(this.state.comments)) {
      this.update_comments();
    }
  };

  update_log_if_needed = () => {
    if (JSON.stringify(this.props.log) !== JSON.stringify(this.state.log)) {
      this.update_log();
    }
  };

  ///////////////
  // Log handling
  ///////////////

  update_log = () => {
    var log_items = this.logToItems(this.props.log);
    this.setState({ log: this.props.log, log_list: log_items }, () => this.scrollToLogEnd());
  };

  scrollToLogEnd = () => {
    if (this.log_ref.current !== null)
      this.log_ref.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  };

  logToItems = (log) => {
    if (log === null || log === undefined) {
      return [];
    }
    var templist = [];
    var itemnum = 0;
    for (var item of log) {
      var what = item.what;
      var name = item.name;
      var date = new Date(item.timestamp * 1000);
      var newitem = (
        <tr key={'logitem' + itemnum++}>
          <td>{date.toLocaleDateString()}</td>
          <td>{date.toLocaleTimeString()}</td>
          <td>{name}</td>
          <td>{what}</td>
        </tr>
      );
      templist.push(newitem);
    }
    return templist;
  };

  ///////////////////
  // Comment handling
  ///////////////////

  update_comments = () => {
    var comment_bubbles = this.commentsToBubbles(this.props.comments);
    this.setState({ comments: this.props.comments, comments_list: comment_bubbles }, () => this.scrollToChatEnd());
  };

  scrollToChatEnd = () => {
    if (this.chat_ref.current !== null)
      this.chat_ref.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  };

  commentsToBubbles = (comments) => {
    if (comments === null || comments === undefined) {
      return [];
    }
    var templist = [];
    var itemnum = 0;
    for (var item of comments) {
      var comment = item.comment;
      var name = item.name;
      var date = new Date(item.timestamp * 1000);
      var showname = 'Me';
      var bt = 'bubbleme';
      if (this.props.user_name !== name) {
        showname = name;
        bt = 'bubblenotme';
      }
      var newitem = (
        <div key={'chatb' + itemnum++} className={bt}>
          <div>
            <p>
              {'Sent by: '}
              <strong>{showname}</strong>
              {' On: ' + date.toLocaleDateString() + ', ' + date.toLocaleTimeString()}
            </p>
            <p>{comment}</p>
          </div>
        </div>
      );
      templist.push(newitem);
    }
    return templist;
  };

  handleCommentChange = (e) => {
    this.setState({ comment: e.target.value });
  };

  handleCommentPost = (e) => {
    if (this.state.comment.length < 1) return;
    let json = { comment: this.state.comment, for: this.props.selected_key };
    this.props.send_func(json);
    this.props.on_event('on_comment');
    this.setState({ comment: '' });
  };
}

export default PageReview;
