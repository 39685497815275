////////////////////////////////////
// Recursively build list of options
////////////////////////////////////

const item_values_recursive = (item, key) => {
  var results = [];
  // if empty item just return
  if (item === undefined) return results;
  // remove initial / if exists
  if (key.lastIndexOf('/', 0) === 0) {
    key = key.substring(1);
  }
  // split path, if there is anything to process do it
  var path = key.split('/');
  if (path.length <= 0) return results;
  var newpath = key.split('/');
  newpath.shift();
  newpath = newpath.join('/');
  // ** is glob, process all the keys, indexes etc
  if (path[0] === '**') {
    // array or object
    if (Array.isArray(item) || Object.keys(item).length >= 0) {
      for (var ind in item) {
        if (newpath.length > 0) {
          results = results.concat(item_values_recursive(item[ind], newpath));
        } else {
          results.push('' + item[ind]);
        }
      }
    } else {
      // key
      // this should not happen with glob?
      return results;
    }
  } else {
    // last key
    if (path.length === 1) {
      results.push('' + item[path[0]]);
    } else {
      // everything else, move down the path
      results = results.concat(item_values_recursive(item[path[0]], newpath));
    }
  }
  // wrap
  return results;
};

///////////////////////////
// Process single data item
///////////////////////////

const item_values = (item, keys, replaces) => {
  var results = [];
  for (var key of keys) {
    var al = item_values_recursive(item, key);
    results = results.concat(al);
  }
  results = [...new Set(results)];
  // replace strings in a list
  if (replaces !== undefined) {
    for (var r of Object.keys(replaces)) {
      for (var x in results) {
        if (results[x] === r) results[x] = replaces[r];
      }
    }
  }
  // wrap
  return results.sort((a, b) => a.localeCompare(b));
};

/////////////////////////
// Process data in a loop
/////////////////////////

const get_data_values = (data, keys, replaces) => {
  var final_list = [];
  for (var itemkey in data) {
    var al = item_values(data[itemkey], keys, replaces);
    final_list = final_list.concat(al);
  }
  final_list = [...new Set(final_list)];
  return final_list.sort((a, b) => a.localeCompare(b));
};

// Export

export { item_values, get_data_values };
