import React, { Component } from 'react';

import { Button, Modal, Alert, Form } from 'react-bootstrap';

class PageLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      un: '',
      pw: ''
    };
  }

  render = () => {
    return (
      <Modal show={true} centered>
        <Modal.Header>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.showLoginAlert === true && <Alert variant="danger">Login incorrect!</Alert>}
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Enter Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Username"
                value={this.state.un}
                onChange={this.handleUNChange.bind(this)}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Enter Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Password"
                value={this.state.pw}
                onChange={this.handlePWChange.bind(this)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={(e) => this.doLogin()}>
            LOG IN
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  handleUNChange = (e) => {
    this.setState({ un: e.target.value });
  };

  handlePWChange = (e) => {
    this.setState({ pw: e.target.value });
  };

  doLogin = () => {
    this.props.login_func(this.state.un, this.state.pw);
  };
}

export default PageLogin;
